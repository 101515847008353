/*
**
** @Description: 登录页面
** @Path: '/'
*/
<template>
  <div class="pl30 pr30">
    <div class="title fBlod">
      登录
    </div>
    <van-form ref="forms" @submit="onSubmit">
      <div class="pl85 pr85 pt100">
        <div>
          <van-field
            v-model="formData.username"
            name="username"
            placeholder="请输入手机号码"
            label="手机号"
            :rules="[
              { required: true, message: '请输入手机号码' }
            ]"
          >
          </van-field>
        </div>
        <div class="pt30">
          <van-field
              v-model="formData.password"
              name="password"
              placeholder="请输入验证码"
              label="验证码"
              :rules="[
              { required: true, message: '请输入密码' }
            ]"
          >
            <template #button>
              <div>
                获取验证码
              </div>
            </template>
          </van-field>
        </div>
        <div style="padding-top: 10vw;">
          <van-button block type="info" native-type="submit" class="main-btn fs34">
            登录/注册
          </van-button>
        </div>
        <div class="pt40">
          <van-checkbox v-model="checked" checked-color="#ffc107" shape="square">
            <div class="fs28 c-333">我已经阅读并同意《隐私条款》和《用户协议》</div>
          </van-checkbox>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checked: false,
      formData: {}
    }
  },
  methods: {
    async onSubmit () {
      this.$toast.success('登录成功')
      this.$router.push('/')
    },
  }
}
</script>

<style lang="less" scoped>
.title{
  padding-top: 10vw;
  font-size: 8vw;
  padding-bottom: 5vw;
}
.leftIcon {
  width: 3.33vw;
}
/deep/ .van-cell {
  border-bottom: 1px solid #eee;
  padding-left: 5.33vw;
  padding-right: 5.33vw;
}
.main-btn {
  background: #ffc107;
  border-color: #ffc107;
}
</style>
